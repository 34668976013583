























































































































































import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class CVPreview extends Vue {
  educations = [
    {
      col1: "Haraldsbogymnasiet",
      col2: "Estetiska programmet inriktning musik",
      col3: "2008-2011",
    },
    {
      col1: "Balettakademien Stockholm",
      col2: "Musikallinjen",
      col3: "2016-2017",
    },
    {
      col1: "Balettakademien Göteborg",
      col2: "Musikalutbildningen",
      col3: "2017-2020",
    },
  ];

  schoolProductions = [
    {
      col1: "The Diner",
      col2: "Jamie",
      col3: "Balettakademien Stockholm",
      col4: "Anna-Karin Hirdwall / Adrienne Åbjörn",
      col5: "2017",
    },
    {
      col1: "Lucia",
      col2: "Solist/Körist",
      col3: "Göteborgs Stadsteater",
      col4: "Musikalklass 2",
      col5: "2018",
    },
    {
      col1: "City of Angels",
      col2: "Stone",
      col3: "Balettakademien Göteborg",
      col4: "Emelie Sigelius / Rebecca Evanne",
      col5: "2019",
    },
    {
      col1: "Flashdance",
      col2: "Joe",
      col3: "Balettakademien Göteborg",
      col4: "Reine Lööf / Gunilla Olsson",
      col5: "2020",
    },
  ];

  sceneExperiences = [
    {
      col1: "Hairspray",
      col2: "Ensemble",
      col3: "Lindberghallen, Djurås",
      col4: "Stina-Kari Axelsson",
      col5: "2012",
    },
    {
      col1: "Celebration",
      col2: "Artist",
      col3: "Lindberghallen, Djurås",
      col4: "Stina-Kari Axelsson",
      col5: "2013",
    },
    {
      col1: "Final i Svensktoppen Nästa",
      col2: "Artist",
      col3: "Säterdalen, Säter",
      col4: "SR: P4 Dalarna",
      col5: "2014",
    },
    {
      col1: "Grabbarna på bruket",
      col2: "Albin",
      col3: "Gammelgården, Borlänge",
      col4: "Ulrika Andersson",
      col5: "2014",
    },
    {
      col1: "Footloose",
      col2: "Ren",
      col3: "Maximteatern, Borlänge",
      col4: "Ulrika Andersson",
      col5: "2014",
    },
    {
      col1: "Final i Svensktoppen Nästa",
      col2: "Artist",
      col3: "Säterdalen, Säter",
      col4: "SR: P4 Dalarna",
      col5: "2015",
    },
    {
      col1: "Grabbarna på bruket",
      col2: "Albin",
      col3: "Gammelgården, Borlänge",
      col4: "Ulrika Andersson",
      col5: "2015",
    },
    {
      col1: "Super Trouper",
      col2: "Dansare",
      col3: "Maximteatern, Borlänge",
      col4: "Ulrika Andersson/Emil Bewö-Lundblad",
      col5: "2016",
    },
    {
      col1: "Mary Poppins",
      col2: "Swing Ensemble",
      col3: "Svenska Teatern, Helsingfors",
      col4: "Markku Nenonen/Osku Heiskanen /Säde Kajovaara",
      col5: "2021-2022",
    },
    {
      col1: "Next to Normal",
      col2: "Henrik",
      col3: "Svenska Teatern, Helsingfors",
      col4: "Markus Virta",
      col5: "2021-2022",
    },
  ];
}
