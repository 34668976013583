

















import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class Video extends Vue {
  videoItems = [
    {
      title: "Next To Normal - Trailer",
      src: "https://www.youtube.com/embed/SNtK2XQLotY"
    },
    {
      title: "Mary Poppins - Trailer",
      src: "https://www.youtube.com/embed/02jqJHYJGXY"
    }
    // {
    //   title: "Moving too fast - The Last Five Years",
    //   src: "https://www.youtube.com/embed/2OdLXcZ1HoA",
    // },
    // {
    //   title: "Street/Funk Dance",
    //   src: "https://www.youtube.com/embed/KbnTFqUDNBE",
    // },
    // {
    //   title: "Flashdance 2020",
    //   src: "https://www.youtube.com/embed/RusYdg3L7kI",
    // },
    // {
    //   title: "Kräver ingen kärlek - Tänk Om",
    //   src: "https://www.youtube.com/embed/tVvVMvs9_PY",
    // },
  ];
}
