













































import Component from "vue-class-component";
import Vue from "vue";
import CvHtml from "./CvHtml.vue";
import CVPreview from "./CVPreview.vue";

@Component({
  components: {
    CvHtml,
    CVPreview,
  },
})
export default class CV extends Vue {}
