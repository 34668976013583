













































































import Header from "@/components/Header.vue";
import About from "@/components/About.vue";
import TempBanner from "@/components/TempBanner.vue";
import Contact from "@/components/Contact.vue";
import CV from "@/components/CV.vue";
import Video from "@/components/Video.vue";
import Current from "@/components/Current.vue";
import ImageGallery from "@/components/ImageGallery.vue";

import { Component, Vue } from "vue-property-decorator";


@Component({
  components: {
    Header,
    About,
    TempBanner,
    Current,
    Contact,
    CV,
    Video,
    ImageGallery
  },
})
export default class Home extends Vue {
  expandNav = false;
  showMobileMenu = false;

  handleScroll(e: any) {
    this.expandNav = window.scrollY > 500;
  }

  menuClick() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  mobileMenuItemClick(el: string) {
    this.showMobileMenu = !this.showMobileMenu;
     setTimeout(() => { this.$scrollTo(el, 800, {easing: "ease", offset: -55})}, 200)
  }

  closeMenu() {
    this.showMobileMenu = false;
  }

  created() {
    window.addEventListener("scroll", this.handleScroll);

    if (location.hostname.endsWith('netlify.app')) {
      location.replace(`https://pontussimm.com`);
    }
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
