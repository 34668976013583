




















































import Component from "vue-class-component";
import Vue from "vue";
import n2nBanner from "../assets/n2nbanner.jpg";
import n2n0 from "../assets/n2n0.jpeg";
import n2n1 from "../assets/n2n1.jpg";
import n2n2 from "../assets/n2n2.jpg";
import n2n3 from "../assets/n2n3.jpg";
import poppinsBanner from "../assets/poppins.jpg";
import VueGallerySlideshow from "vue-gallery-slideshow";
import Modal from "./Modal.vue";

@Component({
  components: {
    VueGallerySlideshow,
    Modal
  },
})
export default class Current extends Vue {
  index = null;

  currentItems = [
    {
      title: "Next to normal",
      location: "Svenska teatern",
      link: "https://www.svenskateatern.fi/sv/repertoar/next_to_normal/",
      banner: n2nBanner,
      bannerCred: "Svenska Teatern / Valtteri Kantanen",
      images: [
        { url: n2n0, style: "object-position: 0px 10%;", cred: "Svenska Teatern / Valtteri Kantanen" },
        { url: n2n1, cred: "Svenska Teatern / Cata Portin"},
        { url: n2n2, cred: "Svenska Teatern / Cata Portin"},
        { url: n2n3, cred: "Svenska Teatern / Cata Portin"},
      ],
    },
    {
      title: "Mary Poppins",
      location: "Svenska teatern",
      link: "https://www.svenskateatern.fi/sv/repertoar/marypoppinsmusical/",
      banner: poppinsBanner,
      bannerCred: "Svenska Teatern / Valtteri Kantanen",
      images: [],
    },
  ];
  
}

