import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueParallaxJs from 'vue-parallax-js'
import VueScrollTo from 'vue-scrollto'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import Vuetify from '@/plugins/vuetify' // path to vuetify export


Vue.config.productionTip = false;
Vue.use(VueParallaxJs)
Vue.use(VueScrollTo)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vuetify)



new Vue({
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.isMobile = this.windowWidth < 989;
    },
    handleScroll() {
      this.scrollY = window.scrollY;
    }
  },
  router: router,
  render: (h) => h(App),

  data() {
    return {
      windowWidth: window.innerWidth,
      isMobile: false,
      scrollY: 0
    }
  },

  created() {
    AOS.init()
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      window.addEventListener("scroll", this.handleScroll);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
})
  .$mount("#app");
