













import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class Header extends Vue {
  overlayOpacity = 0;

  get style() {
    return "opacity: " + this.overlayOpacity;
  }

  handleScroll(e: any) {
   // console.log(this.overlayOpacity);
  }

  created() {
    window.addEventListener("scroll", this.handleScroll);
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
