





























import Component from "vue-class-component";
import Vue from "vue";
import VueGallerySlideshow from "vue-gallery-slideshow";
import Modal from "./Modal.vue";

import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img5.jpg";
import img6 from "../assets/img6.jpg";
import img7 from "../assets/img7.jpg";
import img10 from "../assets/img10.jpg";
import img11 from "../assets/img11.jpg";
import pontus_scen from "../assets/pontus_scen.jpg";
import pontus_stege from "../assets/pontus_stege.jpg";
import thinking2 from "../assets/thinking2.jpg";
import thinking3 from "../assets/thinking3.jpg";
import thinking4 from "../assets/thinking4.jpg";
import thinking5 from "../assets/thinking5.jpg";
import flashdance from "../assets/flashdance.jpg";
import profile from "../assets/profile.jpg";




@Component({
  components: {
    VueGallerySlideshow,
    Modal
  },
})
export default class Current extends Vue {
  index = null;
  images = [
    { url: img4, style: "object-position: 0px 16%;", cred: "Harald Nilsson Fotograf"}, 
    { url: thinking5, cred: "Phil Baro Thomas" },
    { url: img5, style: "object-position: 0px 25%;", cred: "Harald Nilsson Fotograf" },
    { url: flashdance, style: "object-position: 0px 0%;", cred: "Harald Nilsson Fotograf" }, 
    { url: img2, cred: "Harald Nilsson Fotograf" }, 
    { url: profile, cred: "Bildjournalist Mickan Palmqvist" }, 
    // { url: pontus_stege, style: "object-position: 0px 25%;" }, 
    // { url: img6, style: "object-position: 0px 40%;" }, 
    // { url: img7 }, 
    // { url: img11, style: "object-position: 0px 25%;" },
    // { url: pontus_scen }, 
    // { url: thinking3 },
  ];
}
